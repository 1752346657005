<template>
    <div class="result">
        <div class="result-wrapper">
            <h1>{{ $t('assessment_result') }}</h1>

            <div v-if="assessments.length > 0" class="result-container">
                <div
                    class="result-wrapper"
                    v-for="(item, index) in assessments"
                    :key="index"
                >
                    <div class="date">{{ item.created_at | formatDate }}</div>

                    <div class="cards-container">
                        <div 
                            class="card"
                            v-for="(card, key) in item.preview"
                            :key="key"
                            :style="{
                                'background-image': `url(${require(`@/assets/img/results/${card.assessment_type_id}-${language}.png`)})`,
                                'background-size': '100% 100%',
                                'background-repeat': 'no-repeat',
                            }"
                        >
                            <div class="indicators">
                                <div class="points">
                                    <span>{{ card.type_count }}</span>
                                </div>

                                <div class="percent">
                                    <span>{{ card.percent }}%</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <router-link :to="{ name: 'assessment-result', params: { id: item.id }}">{{ $t('show_all_result') }}</router-link>
                </div>
            </div>

            <div v-else class="no-result">
                <img :src="require('@/assets/img/no_file.png')" alt="no record">
                <p>{{ $t('no_result_found') }}</p>
                <button @click="takeTest">{{ $t('take_test') }}</button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapFields } from 'vuex-map-fields'

export default {
    computed : {
        ...mapFields(['user', 'assessments', 'language'])
    },

    async created () {
        await this.$store.dispatch('getAssessments')
    },

    methods: {
        async takeTest () {
            // let res = await this.$store.dispatch('takesChecker')
            
            // if (res.data.takes < 2) {
                if (this.user.subscription.id && this.user.subscription.takes > 0) {
                    this.$router.push('card-assessment').catch(()=>{})
                } else {
                    this.$store.commit('TOGGLE_BANNER', {
                        status: true,
                        message: this.$t('no_takes_left')
                    })
                    this.$router.push('packages').catch(()=>{})
                }
            // } else {
            //     this.$store.commit('TOGGLE_BANNER', {
            //         status: true,
            //         message: this.$t('reached_limit')
            //     })
            // }
        }
    }
}
</script>

<style lang="scss" scoped>
.result {
    color: #221E1F;

    .result-wrapper {
        max-width: 1200px;
        padding: 30px 20px;
        margin: auto;
        
        h1 {
            font-family: 'Crimson Pro', sans-serif;
            font-size: 26px;
            font-weight: 700;
            padding-bottom: 24px;
            border-bottom: 1px solid #B8B8B8;
            text-align: center;
        }

        .result-container {
            margin-top: 30px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            .result-wrapper {
                padding: 10px 15px;
                margin: 0 10px 10px 0;
                border: 1px solid #707070;
                border-radius: 4px;
                display: flex;
                flex-direction: column;
                align-items: center;

                @include desktop {
                    padding: 15px 40px 20px;
                }

                .date {
                    font-family: 'Crimson Pro', sans-serif;
                    font-size: 26px;
                    margin-bottom: 15px;
                }

                .cards-container {
                    display: flex;
                    flex-flow: row wrap;
                    margin-bottom: 16px;
                    justify-content: center;

                    .card {
                        width: 140px;
                        height: 205px;
                        margin-right: 10px;
                        margin-bottom: 10px;
                        background-color: #CFA0B5;
                        padding-bottom: 5px;
                        display: flex;
                        flex-direction: column-reverse;
                        align-items: center;
                        border-radius: 4px;

                        @include desktop {
                            margin-bottom: 0;
                        }

                        .indicators {
                            display: flex;

                            .points {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                                background-color: #45B171;
                                margin-right: 10px;
    
                                span {
                                    color: #ffffff;
                                    font-size: 9px;
                                }
                            }
    
                            .percent {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                                background-color: #ffffff;
                                border: 2px solid #00AEEF;
    
                                span {
                                    color: #00AEEF;
                                    font-size: 9px;
                                }
                            }
                        }
                        
                    }
                }

                a {
                    color: #B8B8B8;
                    font-weight: 600;
                    font-size: 14px;
                    cursor: pointer;
                }
            }
        }

        .no-result {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 110px;

            img {
                width: 200px;
                height: 150px;
                margin-bottom: 15px;
            }

            p {
                color: #B8B8B8;
                margin-bottom: 15px;
            }

            button {
                background-color: #00AEEF;
                width: 120px;
                font-weight: 600;
                font-size: 14px;
                color: #ffffff;
            }
        }
    }
}
</style>